.donate-organization-item {
  width: 670px;
  height: 110px;
  background-color: #FFFFFF!important;
  box-shadow: 0px 4px 12px rgb(124 177 255 / 25%);
  border-radius: 4px;
  margin-left: auto;
  margin-right: auto;
  background: url("../../../images/statBg.svg");
  background-repeat: no-repeat;
  background-position: top left;
  background-size: 85px;
}

.donate-organization-item-row {
  padding: 20px;
  width: 100%;
}

.donate-org-col-one {
  flex-grow: unset;
  width: 20%;
  padding-left: 20px;
}

.donate-org-col-two {
  width: 40%;
}

.donate_org_logo {
  position: relative;
  bottom: 5px;
  width: 70px;
  height: 70px;
  border: 1px solid #67DB88;
  box-sizing: border-box;
}

.donate_org_name {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #263238;
  width: max-content;
}

.donate_value-div {
  display: flex;
  position: relative;
  top: 10px;
}

.org_money_img {
  width: 20px;
  height: 20px;
}

.org_donate_value-p {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #263238;
}

.donate-org-col-three {
  width: 40%;
}

.donate_badge_status {
  width: 85px;
  height: 22px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  background-color: rgb(124, 177, 255);
  color: #FFFFFF;
  text-align: center;
  padding: 4px;
  margin-left: auto;
}

.org_donation_date-div {
  display: flex;
  position: relative;
  top: 10px;
  float: right;
}

.org_donation_date-lbl {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #90AD98;
  margin-right: 1rem!important;
}

.org_donation_date-txt {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  color: #263238
}

.donate_search-input {
  color: rgb(144, 173, 152);
  width: 280px;
  background: #FFFFFF;
  min-height: 38px;
  height: 33px;
}

@media (max-width: 720px) {
  .donate-organization-item {
    padding-top: 18px;
    width: 100%;
    height: 100px;
    background-size: 75px;
  }
  .donate-organization-item-row {
    width: auto;
  }
  .donate_org_logo {
    bottom: 30px;
  }
  .donate-org-col-one {
    width: auto;
  }
  .donate-org-col-two {
    position: relative;
    bottom: 30px;
    padding-left: 15px;
  }
  .donate-org-col-three {
    position: relative;
    bottom: 70px;
    margin-left: auto;
  }
  .donate_org_name {
    font-weight: 600;
    font-size: 12px;
  }
  .org_donate_value-p {
    font-size: 14px;
    width: max-content;
  }
  .donate_badge_status {
    position: relative;
    bottom: 5px;
  }
  .donate_value-div {
    top: 4px!important;
    width: max-content;
  }
  .org_donation_date-div {
    top: 5px;
  }
  .donate_search-input {
    width: 100%;
  }
  .org_donation_date-txt {
    font-size: 10px;
  }
  .org_donation_date-lbl {
    font-size: 10px;
    margin-right: 5px!important;
    width: max-content;
  }
}
